var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"}),_c('div',{staticClass:"col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataFilter),expression:"dataFilter"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Type to search by email or company name"},domProps:{"value":(_vm.dataFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dataFilter=$event.target.value}}})])]),_c('div',{staticClass:"table-responsive dataTables_wrapper"},[_c('b-table',{staticClass:"dataTable",attrs:{"bordered":"","hover":"","small":"","items":_vm.dataTable,"fields":_vm.columns,"filter":_vm.dataFilter},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.deletedAt === null)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn btn-primary btn-sm",attrs:{"content":'View data'},on:{"click":function($event){return _vm.view(item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")])]):_vm._e(),(item.status === 'Active' && _vm.$userCan(['administrator']) && item.id !== 1 && item.deletedAt === null)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn btn-warning btn-sm",attrs:{"content":'Deactivate user'},on:{"click":function($event){return _vm.changeStatus(item.id, 'new')}}},[_c('i',{staticClass:"material-icons"},[_vm._v("clear")])]):_vm._e(),(item.status ==='Inactive' && item.term === 'Incomplete' && item.deletedAt === null && _vm.$userCan(['administrator']))?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn btn-danger btn-sm",attrs:{"content":'Reject request'},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("delete")])]):_vm._e(),(item.deletedAt !== null && _vm.$userCan(['administrator']))?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn btn-success",attrs:{"content":'Active request'},on:{"click":function($event){return _vm.enableUser(item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("check")])]):_vm._e()]}},{key:"cell(country)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.getters["geo/country"](item.countryId).alpha3Code))])]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('credits-view',{attrs:{"amount":item.balance,"no-show-logo":!_vm.showInLocal,"country_id":item.countryId,"symbol-to-end":""}})]}},{key:"cell(zero_balance)",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("color: white; padding: 0.2vw; font-weight: bold; background: " + ((item.zero_balance)?'#17c671':'#d14343')))},[_vm._v(_vm._s((item.zero_balance) ? "Allowed" : "Not allowed"))])]}},{key:"cell(currency_swith)",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("color: white; padding: 0.2vw; font-weight: bold; background: " + ((item.currency_swith)?'#17c671':'#d14343')))},[_vm._v(_vm._s((item.currency_swith) ? "Enabled" : "Disabled"))])]}},{key:"cell(tvs_local)",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("color: white; padding: 0.2vw; font-weight: bold; background: " + ((item.tvs_local)?'#d14343':'#17c671')))},[_vm._v(_vm._s((item.tvs_local) ? "Local" : "TVS"))])]}},{key:"cell(currency)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.numberFilter(item.currency)))])]}},{key:"cell(file_server_enabled)",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{class:[item.file_server_enabled ? 'bg-success' : 'bg-danger'],style:("color: white; padding: 0.2vw; font-weight: bold; cursor:pointer;"),attrs:{"title":item.file_server_enabled ? 'Disable file server' : 'Enable file server'},on:{"click":function($event){return _vm.changeFileServer(item)}}},[_vm._v(_vm._s((item.file_server_enabled) ? "Yes" : "Not")+" ")])])]}},{key:"cell(total)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(item.total)+" ")])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.getStatusClass(item.status)],style:(("color: white; padding: 0.2vw; font-weight: bold; background: " + (_vm.getColor(item.status, 'status'))))},[_vm._v(_vm._s(item.status))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }