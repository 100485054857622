import Vue from 'vue';
import {get} from '@/services/api';
import {getAllUser} from '../../../services/endpoint.json';
import {UserRole} from '@/utils/auth.roles';
import {decrypt} from '@/helpers/encrypt';
import {mapGetters, mapState} from "vuex";
import {AGREEMENT_STATUS, USER_STATUS} from "@/constants";
import SubUser from '../user-sub/index.vue'
import {checkSubUserPermission} from "@/utils/prototypes";
import TableTabs from './DealerManagementAdmin'
import CreditsView from '@/components/common/CreditsView';
import DealerManagement from "@/views/dashboard/user/DealerManagement";


export default Vue.extend({
  name: 'User',
  components: {
    CreditsView,
    SubUser,
    TableTabs,
    DealerManagement
  },
  data: () => ({
    dateRange: {
      from: null,
      to: null,
    },
    columns: [
      {label: 'Email', key: 'email', sortable: true},
      {label: 'Company Name', key: 'company.nameCompany', sortable: true},
      {label: 'Country', key: 'country', sortable: true},
      {label: 'Role', key: 'role', sortable: true},
      {label: 'price_group', key: 'price_group', sortable: true},
      {label: 'Tune Type', key: 'tune', sortable: true},
      {label: 'balance', key: 'balance', sortable: true},
    ],
    tableData: [], //dealers
    tableDataUserTvs: [],
    tableDataUserNew: [],
    tableDataSubDealers: [],
    tableDataDistributor: [],
    tableDataTester: [],
    tableDataDeleted: [],
    userId: null,
    showDetail: false,
    user: {
      id: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      country: '',
      status: '',
      role: []
    },
    roleList: Object.values(UserRole),
    AGREEMENT_STATUS: AGREEMENT_STATUS(),
    USER_STATUS: USER_STATUS(),
    showUsers: false,
    showAccounts: false
  }),
  mounted() {
    if (checkSubUserPermission('user_management.dealer-management') && this.$store.state.buySale.information.haveChildren) {
      this.showUsers = true
      this.showAccounts = false
    } else if (checkSubUserPermission('user_management.account-management')) {
      this.showUsers = false
      this.showAccounts = true
    }
    this.getAllUser().then();
  },
  computed: {
    ...mapState('geo', ['showInLocal']),
    ...mapGetters('geo', {numberFilter: 'numberFilter'})
  },
  created() {
    const tex = localStorage.getItem('admin-type');
    const type = decrypt(tex);
    if (type.includes('administrator')) {
      this.columns.push({label: 'Currency factor', key: 'currency', sortable: true});
      this.columns.push({label: 'File server access', key: 'file_server_enabled', sortable: true})
      this.columns.push({label: 'Zero Balance', key: 'zero_balance', sortable: true});
      this.columns.push({label: 'Currency Switch', key: 'currency_swith', sortable: true});
      this.columns.push({label:'Currency displayed', key:'tvs_local', sortable: true});
      this.columns.push('actions');
    } else {
      this.columns.push('actions');
    }
  },
  methods: {
    async getAllUser() {
      const encryptRole = localStorage.getItem('admin-type');
      const role = decrypt(encryptRole)
      this.$store.commit('loaderManager', true);
      let {data} = await get(getAllUser, null, true);
      if (Number(data.statusCode) === Number(200)) {
        if (role.includes('distributor') || role.includes('dealer')) {
          this.tableData = data.message.map(item => ({
            ...item,
            country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
          }));
        } else if (role.includes('administrator')) {
          this.tableDataUserTvs = data.message.filter(x => ((x.role === 'administrator' || x.role === 'calibrator' || x.role === 'support') && x.id !== 2 && x.id !== 26)).map(item => ({
            ...item,
            country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
          }))

          this.tableDataTester = data.message.filter(x => (x.id === 2 || x.id === 26))
            .map(item => ({
              ...item,
              country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
            }))

          this.tableDataUserNew = data.message.filter(x => ((x.role === 'dealer' || x.role === 'distributor' || x.role === 'subdealer') && x.status == 'Inactive' && x.term == 'Incomplete' && x.deletedAt == null))
            .map(item => ({
              ...item,
              country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
            }))
          this.tableDataDeleted = data.message.filter(x => ((x.role === 'dealer' || x.role === 'distributor' || x.role === 'subdealer') && x.deletedAt !== null))
            .map(item => ({
              ...item,
              country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
            }))

          this.tableData = data.message.filter(x => (x.role === 'dealer' && x.status == 'Active' && x.term == 'Completed' && x.deletedAt == null))
            .map(item => ({
              ...item,
              country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
            }))

          this.tableDataSubDealers = data.message.filter(x => (x.role === 'subdealer' && x.status == 'Active' && x.term == 'Completed' && x.deletedAt == null))
            .map(item => ({
              ...item,
              country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
            }))

          this.tableDataDistributor = data.message.filter(x => (x.role === 'distributor' && x.status == 'Active' && x.term == 'Completed' && x.deletedAt == null))
            .map(item => ({
              ...item,
              country: (item.country.length > 20) ? item.country.substring(0, 20) + '...' : item.country
            }))
        }
      }
      this.$store.commit('loaderManager', false);
    },
    showSection(value) {
      this.showUsers = (value === 'users')
      this.showAccounts = (value === 'accounts')
    },
    view(id) {
      this.$router.push({
        name: 'profile-admin',
        params: {id: id}
      });
    },
  },
});
