<template>
    <b-card>
      <div class="table-responsive">
      <b-table title="Users Listing" hover bordered small :items="users" :fields="columns">
        <template v-if="$checkSubUserPermission('user_management.view-detail')" #cell(actions)="{item}">
          <button
            v-tippy="{ placement : 'top',  arrow: true }" :content="'View detail'"
            class="btn btn-primary" @click="view(item.id)">
            <i class="material-icons">visibility</i>
          </button>
        </template>

        <template #cell(country)="{item}">
          <span>{{$store.getters["geo/country"](item.countryId).alpha3Code}}</span>
        </template>

        <template #cell(balance)="{item}">
          <!--      {{item.balance}}{{item.countryId}}-->
          <credits-view :amount="item.balance" :no-show-logo="!showInLocal" :country_id="item.countryId" symbol-to-end/>
        </template>
        <template #cell(currency)="{item}">
          {{ numberFilter(item.currency) }}
        </template>
      </b-table>
      </div>
    </b-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CreditsView from '@/components/common/CreditsView';
export default {
  name: "TableUsers",
  props:{
    users:{
      default: [],
    },
    columns: {
      default: null
    }
  },
  components: {
    CreditsView
  },
  computed: {
    ...mapState('geo',['showInLocal']),
    ...mapGetters('geo', {numberFilter: 'numberFilter'}),
  },
  data: () => ({

  }),
  methods:{
    view(id){
      this.$emit('view',id)
    },
    getStatusClass(status) {
      const map = {
        Pending: 'warning',
        Complete: 'success',
        Cancelled: 'danger',
      };
      return `text-${map[status]}`;
    },
    getColor(status, type) {
      if (type === 'term') {
        const obj = this.AGREEMENT_STATUS.filter(x => x.value.toString() === status.toString())
        return (obj.length) ? obj[0].color : 'black'
      } else if (type === 'status') {
        const obj = this.USER_STATUS.filter(x => x.value.toString() === status.toString())
        return (obj.length) ? obj[0].color : 'black'
      }
    },
  }

}
</script>

<style scoped>

</style>
