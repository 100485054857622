var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"table-responsive"},[_c('b-table',{attrs:{"title":"Users Listing","hover":"","bordered":"","small":"","items":_vm.users,"fields":_vm.columns},scopedSlots:_vm._u([(_vm.$checkSubUserPermission('user_management.view-detail'))?{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn btn-primary",attrs:{"content":'View detail'},on:{"click":function($event){return _vm.view(item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")])])]}}:null,{key:"cell(country)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.getters["geo/country"](item.countryId).alpha3Code))])]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('credits-view',{attrs:{"amount":item.balance,"no-show-logo":!_vm.showInLocal,"country_id":item.countryId,"symbol-to-end":""}})]}},{key:"cell(currency)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFilter(item.currency))+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }