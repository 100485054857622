<template>
  <div class="w-100">
    <div class="row">
      <div class="col-lg-8"></div>
      <div class="col-lg-4">
        <input type="text" class="form-control" placeholder="Type to search by email or company name"
               v-model="dataFilter">
      </div>
    </div>
    <div class="table-responsive dataTables_wrapper">
      <b-table bordered hover small class="dataTable" :items="dataTable" :fields="columns"
               :filter="dataFilter">
        <template #cell(actions)="{item}">
          <button v-if="item.deletedAt === null"
                  v-tippy="{ placement : 'top',  arrow: true }" :content="'View data'"
                  class="btn btn-primary btn-sm"
                  @click="view(item.id)">
            <i class="material-icons">visibility</i>
          </button>
          <button
            v-if="item.status === 'Active' && $userCan(['administrator']) && item.id !== 1 && item.deletedAt === null"
            v-tippy="{ placement : 'top',  arrow: true }" :content="'Deactivate user'"
            class="btn btn-warning btn-sm"
            @click="changeStatus(item.id, 'new')">
            <i class="material-icons">clear</i>
          </button>
          <button
            v-if="item.status ==='Inactive' && item.term === 'Incomplete' && item.deletedAt === null && $userCan(['administrator'])"
            v-tippy="{ placement : 'top',  arrow: true }" :content="'Reject request'"
            class="btn btn-danger btn-sm"
            @click="deleteUser(item.id)">
            <i class="material-icons">delete</i>
          </button>
          <button v-if="item.deletedAt !== null && $userCan(['administrator'])"
                  v-tippy="{ placement : 'top',  arrow: true }" :content="'Active request'"
                  class="btn btn-success"
                  @click="enableUser(item.id)">
            <i class="material-icons">check</i>
          </button>
        </template>
        <template #cell(country)="{item}">
          <span>{{ $store.getters["geo/country"](item.countryId).alpha3Code }}</span>
        </template>
        <template #cell(balance)="{item}">
          <credits-view :amount="item.balance" :no-show-logo="!showInLocal" :country_id="item.countryId" symbol-to-end/>
        </template>
        <template #cell(zero_balance)="{item}">
          <span
            :style="`color: white; padding: 0.2vw; font-weight: bold; background: ${(item.zero_balance)?'#17c671':'#d14343'}`">{{
              (item.zero_balance) ? "Allowed" : "Not allowed"
            }}</span>
        </template>
        <template #cell(currency_swith)="{item}">
          <span
            :style="`color: white; padding: 0.2vw; font-weight: bold; background: ${(item.currency_swith)?'#17c671':'#d14343'}`">{{
              (item.currency_swith) ? "Enabled" : "Disabled"
            }}</span>
        </template>
        <template #cell(tvs_local)="{item}">
          <span
            :style="`color: white; padding: 0.2vw; font-weight: bold; background: ${(item.tvs_local)?'#d14343':'#17c671'}`">{{
              (item.tvs_local) ? "Local" : "TVS"
            }}</span>
        </template>
        <template #cell(currency)="{item}">
          <span> {{ numberFilter(item.currency) }}</span>
        </template>
        <template #cell(file_server_enabled)="{item}">
          <span>
             <span
               @click="changeFileServer(item)"
               :title="item.file_server_enabled ? 'Disable file server' : 'Enable file server'"
               :style="`color: white; padding: 0.2vw; font-weight: bold; cursor:pointer;`"
               :class="[item.file_server_enabled ? 'bg-success' : 'bg-danger']">{{
                 (item.file_server_enabled) ? "Yes" : "Not"
               }}
             </span>
          </span>
        </template>
        <template #cell(total)="{item}">
          <span class="text-success">{{ item.total }} </span>
        </template>
        <template #cell(status)="{item}">
          <span
            :style="`color: white; padding: 0.2vw; font-weight: bold; background: ${getColor(item.status, 'status')}`"
            :class="[getStatusClass(item.status)]">{{ item.status }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {AGREEMENT_STATUS, USER_STATUS} from "@/constants";
import {mapGetters, mapState} from "vuex";
import CreditsView from "@/components/common/CreditsView";
import {post, put} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Table",
  mixins: [show_alert_mixin],
  props: {
    dataTable: {
      default: []
    },
    columns: {
      default: []
    },
    options: {
      default: []
    },
  },
  components: {
    CreditsView
  },
  computed: {
    ...mapState('geo', ['showInLocal']),
    ...mapGetters('geo', {numberFilter: 'numberFilter'})
  },
  data: () => ({
    dataFilter: '',
    AGREEMENT_STATUS: AGREEMENT_STATUS(),
    USER_STATUS: USER_STATUS(),
  }),
  methods: {
    view(id) {
      this.$router.push({
        name: 'profile-admin',
        params: {id: id}
      });
    },
    getStatusClass(status) {
      const map = {
        Pending: 'warning',
        Complete: 'success',
        Cancelled: 'danger',
      };
      return `text-${map[status]}`;
    },
    getColor(status, type) {
      if (type === 'term') {
        const obj = this.AGREEMENT_STATUS.filter(x => x.value.toString() === status.toString())
        return (obj.length) ? obj[0].color : 'black'
      } else if (type === 'status') {
        const obj = this.USER_STATUS.filter(x => x.value.toString() === status.toString())
        return (obj.length) ? obj[0].color : 'black'
      }
    },
    async changeStatus(id, type) {
      const data = this.dataTable.find(item => item.id === id);
      const msg = ((data.status).toString() === 'Inactive') ? 'Do you want to activate the user?' : 'Do you want to deactivate the user?';
      const text = ((data.status).toString() === 'Inactive') ? '<b style="color: #d14343">Make sure the currency factor is correct!</b>' : '';
      this.$swal({
        title: msg,
        html: text,
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.verifiedUserHaveChildren(id)
        }
      });
    },
    async verifiedUserHaveChildren(id) {
      const {data} = await post('user/verified-have-children', {id: id}, true);
      if (Number(data) > 0) {
        this.$swal({
          title: "Are you sure",
          html: "The user has assigned dealers?",
          showCancelButton: true,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await put('user/' + id + '/status', {status: 0});
            location.reload()
          }
        });
      } else {
        await put('user/' + id + '/status', {status: 0});
        location.reload()
      }
    },
    async deleteUser(id) {
      this.$swal({
        title: "Reject user",
        html: "Are you sure you want to reject this user? It will be moved to the Rejected tab?",
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {data} = await post("user/delete-user-database", {id: id}, true)
          if (data === 200) {
            this.showAlertNotification("User deleted")
            location.reload()
          } else {
            this.showAlertNotification(data, "error")
          }
        }
      });
    },
    async enableUser(id) {
      this.$swal({
        title: "Enable user",
        html: "Are you sure you want to enable this user?",
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {data} = await post("user/enable-user-database", {id: id}, true)
          if (data === 200) {
            this.showAlertNotification("User enabled")
            location.reload()
          } else {
            this.showAlertNotification(data, "error")
          }
        }
      });
    },
    async changeFileServer(item) {
      this.$store.commit('loaderManager', false)
      post('user/switch-file-server', {user_id: item.id, enabled: !item.file_server_enabled}, true)
        .then(({data}) => {
          if (data.enabled) {
            this.showAlertNotification('File server enabled')
          } else {
            this.showAlertNotification('File server disabled')
          }
          item.file_server_enabled = data.enabled
        }).finally(() => this.$store.commit('loaderManager', false))
    }
  }
}
</script>

<style scoped>

</style>
